import './index.scss'
import anime from 'animejs';

if($('#load').length > 0){
  // load
  let $load = $('#load');
  let $loadNum = $('#load-num');
  let $loadProgress = $('#load-progress');
  let num = { num: 0 };

  // 开始的 anime
  let animeStart = anime({
    targets: num,
    num: [ 0, 90 ],
    duration: 7000,
    easing: 'easeInOutCirc',
    update: animeUpdate
  });
  // 当结束后，num直彪100
  $(window).on('load', function () {
    animeStart.pause();
    anime({
      targets: num,
      num: 100,
      duration: 500,
      update: animeUpdate,
      easing: 'easeInOutCirc',
      complete: function () {
        $load.addClass('load-end');
      }
    });
  });
  // 修改视图
  function animeUpdate () {
    $loadNum.attr('data-num', parseInt(num.num));
    $loadProgress.css('width', num.num + '%');
  }
}


