module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {


if (!bannerPath) var bannerPath='';
if (!title) var title = '';
if (!title2) var title2 = '';
;
__p += '\r\n<div class="ny-banner">\r\n  <!-- <b class="bg-img" style="background-image: url(' +
((__t = ( bannerPath )) == null ? '' : __t) +
');"></b> -->\r\n  <div class="swiper mySwiper">\r\n    <div class="swiper-wrapper">\r\n      <div class="swiper-slide" style="background-image: url(' +
((__t = (require('../../assets/images/about_banner1.jpg'))) == null ? '' : __t) +
');"></div>\r\n      <div class="swiper-slide" style="background-image: url(' +
((__t = (require('../../assets/images/quality_banner.jpg'))) == null ? '' : __t) +
');"></div>\r\n      <div class="swiper-slide" style="background-image: url(' +
((__t = (require('../../assets/images/solution_banner.jpg'))) == null ? '' : __t) +
');"></div>\r\n    </div>\r\n    <div class="swiper-pagination"></div>\r\n  </div>\r\n  <div class="banner-text">\r\n    <div class="left">\r\n      <h1>' +
((__t = ( title )) == null ? '' : __t) +
'</h1>\r\n      <h2>' +
((__t = ( title2 )) == null ? '' : __t) +
'</h2>\r\n    </div>\r\n    <div class="line"></div>\r\n    <div class="right">\r\n      <div class="mouse"><img src="' +
((__t = (require('../../assets/images/darkgreen_icon_shubiao.png'))) == null ? '' : __t) +
'" alt=""></div>\r\n    </div>\r\n  </div>\r\n</div>';

}
return __p
}