import './index.scss'



$('[data-open="cmenu"]').on('click', function () {
  if($('header .menu-icon').hasClass('active')){
    $('header .menu-icon').removeClass('active')
    $('header .hamburger').removeClass('active')
    $('header .mob-header').removeClass('show')
    $('header .mask').fadeOut(200)
  }else{
    $('header .menu-icon').addClass('active')
    $('header .hamburger').addClass('active')
    $('header .mob-header').addClass('show')
    $('header .mask').fadeIn(200)
  }
})


$('.mob-header ul li .title .iconfont').on('click',function(){
  let $parentEl = $(this).parents('li')
  if($parentEl.hasClass('active')){
    $parentEl.removeClass('active')
    $parentEl.find('.sub-nav').slideUp()
  }else{
    $parentEl.addClass('active').siblings().removeClass('active')
    $parentEl.find('.sub-nav').slideDown()
    $parentEl.siblings().find('.sub-nav').slideUp()
  }
})

$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度

  if (scroH > 100) {
    $('header .menu-icon').addClass('scroll')
  }else{
    $('header .menu-icon').removeClass('scroll')
  }
  

});