import './index.scss'
import Swiper from 'swiper/swiper-bundle.min'

if($('.ny-banner .swiper').length > 0){
  var nyBannerswiper = new Swiper(".ny-banner .swiper", {
    watchOverflow: true,
    effect : 'fade',
    fadeEffect: {
      crossFade: true,
    },
    speed: 600,
    autoplay: {
      delay: 5000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.ny-banner .swiper .swiper-pagination',
      clickable: true
    },
  });
}
